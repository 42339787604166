<template>
  <v-container fluid class="pa-0">
    <v-card
      outlined
      min-height="100vh"
      color="#27F0CE"
      class="pa-0"
      elevation="0"
    >
      <v-container class="pa-0 pa-md-5" style="max-width: 1450px">
        <v-row class="mx-0 ">
          <v-col cols="12" md="3" class="pa-0 d-none d-md-block" order="2" order-md="1" >
            <slot name="grid-left"></slot>
          </v-col>
          <v-col cols="12" md="9" class="pa-0" order="1">
            <slot name="grid-right"></slot>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      //
    }
  },
  methods:{

  }
}
</script>

<style scoped>
</style>